import styled from "styled-components"

const Body = styled.section`
  width: 55%;
  margin: 0 auto;
  padding: 4.5rem 0 3.5rem;
  border-bottom: solid 1px #e9e9e9;
  margin-bottom: 14rem;
  font-size: 1.125rem;
  line-height: 2rem;

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    margin-bottom: 2.5rem;
    overflow: hidden;
    border-radius: 24px;
  }

  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  p {
    padding-bottom: 2rem;
  }

  ul,
  ol {
    width: 80%;
    margin: 0 auto;
    padding: 1.5rem 0 2.5rem;
  }

  ul li {
    list-style: disc inside;
  }

  ol li {
    list-style: decimal inside;
  }

  a {
    color: #009572;
    &:hover {
      text-decoration: underline;
    }
  }

  li {
    padding-bottom: 1.125rem;
  }

  h2 {
    font-size: 1.875rem;
    padding-top: 3.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.75rem;
    letter-spacing: -0.2px;
  }

  h3 {
    font-size: 1.125rem;
    padding-bottom: 1.5rem;
  }

  h3,
  h4 {
    font-family: var(--xn-font-body);
    font-weight: 700;
  }

  h4 {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: -0.3px;
    padding-bottom: 0.75rem;
    color: ${props => props.theme.mint600};
  }

  .xn-socials {
    a {
      margin-right: 0;
    }

    div {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  @media screen and (max-width: 766px) {
    width: calc(100% - 4rem);
    font-size: 1.2rem;

    p,
    li {
      line-height: 2rem;
    }
  }
`

export default Body
